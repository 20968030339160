import { Body, PageLayout } from 'components/common';
import BottomSheet, { BottomSheetRef } from 'components/common/BottomSheet';
import { useCallback, useEffect, useRef, useState } from 'react';

import Button from 'components/common/Button';
import CardSlider from 'components/view/CardSlider';
import Icon from 'components/common/Icon';
import { Player } from '@lottiefiles/react-lottie-player';
import { Product } from 'types/model';
import animationData from '../../lotties/time_buff_infinity.json';

const TimeBuffDiscountModal: React.FC = () => {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [open, setOpen] = useState<boolean>();
  const [products, setProducts] = useState<Product[]>([]);
  const ref = useRef<BottomSheetRef>(null);
  const rtl = BResource.getSelectedLocale() === 'ar';

  const addBackButtonPressCallback = useCallback(() => {
    CommonUI.addReactBackButtonCallback(showCloseConfirmBottomSheet);
  }, []);

  const showCloseConfirmBottomSheet = () => {
    CommonUI.addReactBackButtonCallback(ref.current?.hide);
    ref.current?.show();
  };

  useEffect(() => {
    setOpen(true);
    setProducts(TimeBuffManager.getDiscountProduct() ?? []);

    addBackButtonPressCallback();
  }, [addBackButtonPressCallback]);

  const hide = (to = Transition.getPrevHistoryName()) => {
    return new Promise((resolve) => {
      setOpen(false);
      ref.current?.hide();
      setTimeout(() => {
        Transition.resetToHome();
        resolve(to);
      }, 500);
    });
  };

  const handleClickPurchase = () => {
    const product = products[selectedIdx];
    PurchaseManager.attach((err: any, purchaseCoin: any, productId: string) => {
      if (!err && productId.includes('timebuff')) {
        // settimeout을 안하면 실행 안됨. 왜 일까
        hide(Def.TAB_HOME).then(() => setTimeout(() => Transition.showPageWithoutHide(Def.BOTTOM_SHEET_TIME_BUFF_PURCHASE_COMPLETE), 0));
      }
    });
    PurchaseManager.buyCoin(product.productId);
    TimeBuffLogger.logPurchase('clickQuantity', product.productId);
  };

  return (
    <PageLayout overlay>
      <Body
        onClose={hide}
        className="flex-col justify-center h-full pt-[var(--status-bar-height)] static"
        style={{
          background: 'linear-gradient(180deg, #000 19.5%, #FF9AC4 100%)',
          transition: 'all 0.3s ease-in-out',
          transform: open ? 'unset' : 'translateY(100%)',
        }}
      >
        <Icon
          name="cafe/close.svg"
          className="absolute z-10"
          onClick={() => showCloseConfirmBottomSheet()}
          style={{ top: 'calc(var(--status-bar-height) + 16px)', right: rtl ? 'unset' : '16px', left: rtl ? '16px' : 'unset' }}
        />
        <div className="h-[40px]" />
        <div className="text-[#FF4390] text-[96px] font-black w-full text-center">{-50}%</div>
        <div className="text-white text-center whitespace-pre-wrap text3 mt-2">{brsc.dicount_time_buff_popup_1.replace('<br>', '\n')}</div>
        <CardSlider
          className="py-12 px-24"
          items={products}
          buildItems={(item, index) => <CardItem key={`${item.productId}_${index}`} product={item} selected={index === selectedIdx ? true : false} />}
          onSelect={setSelectedIdx}
          currentIndex={selectedIdx}
        />
        <Button style={{ text: brsc.purchase }} className="mx-4" onClick={handleClickPurchase} />
        <div className="flex p-4 mt-2 flex-column items-start justify-center text-center text-white gap-2">
          <div className="text-title3 pl-2">Check point☝️</div>
          <ul className="text-4 text-start py-0 px-4 m-0">
            <li>{brsc.dicount_time_buff_popup_2}</li>
            <li>{brsc.dicount_time_buff_popup_3}</li>
          </ul>
        </div>
      </Body>
      <BottomSheet ref={ref} className="">
        <div className="text-title3 text-text-700 text-center pt-6">{brsc.dicount_time_buff_popup_4}</div>
        <div className="text4 text-text-300 text-center py-3">{brsc.dicount_time_buff_popup_2}</div>
        <Button onClick={() => ref.current?.hide()} className="mt-4" style={{ text: brsc.pc_modal_back, color: 'primary-700', fontColor: 'white' }} />
        <Button onClick={() => hide()} className="mt-4" style={{ text: brsc.leave, color: 'gray-400', fontColor: 'text-300', type: 'text' }} />
      </BottomSheet>
    </PageLayout>
  );
};

export default TimeBuffDiscountModal;

interface CardItemProps {
  product: Product;
  selected: boolean;
}

const CardItem: React.FC<CardItemProps> = ({ product, selected }) => {
  const emojiMap: { [key: number]: string } = { 1: '', 3: '✨', 5: '🔥' };
  const { count, price, originalPrice, price_currency_code } = product;
  const emoji = emojiMap[count];

  const background = selected ? 'linear-gradient(154deg, rgba(255, 173, 207, 0.50) 0%, rgba(255, 67, 144, 0.50) 100%)' : 'rgba(255, 255, 255, 0.30)';
  const border = selected ? '1px solid #FF4390' : undefined;
  return (
    <div className="px-4 py-10 flex flex-col text-white rounded-lg gap-4" style={{ background, border, minWidth: '200px', maxWidth: '200px' }}>
      <div className="text-title2 flex whitespace-nowrap justify-center">{brsc.time_buff + ' ' + brsc.count_item.replace('$param', count)}</div>
      {selected && (
        <div className="flex flex-col">
          <Player autoplay loop src={animationData} style={{ width: '120px', height: '60px' }} />
          <div className="text4 text-center">
            {emoji}
            {count * (TimeBuffManager.BUFF_DURATION / (1000 * 60))}min{emoji}
          </div>
        </div>
      )}
      <div className="flex flex-row gap-2 align-center justify-center">
        <div className="relative inline-block w-fit">
          <div className="diagonal-text text-4 h-fit w-fit">{PageShop.numberToCurrencyString(originalPrice, price_currency_code)}</div>
        </div>
        <div className="text-title3">{PageShop.numberToCurrencyString(price, price_currency_code)}</div>
      </div>
    </div>
  );
};
