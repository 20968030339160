import { useBottomSheet } from './BottomSheet';

interface BodyProps {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  overlay?: boolean;
  onClose?: () => void;
}

const Body: React.FC<BodyProps> = ({ children, className = '', backgroundColor, style, overlay = false, onClose }) => {
  const { divRef, swiping } = useBottomSheet({ onClose, swipe: overlay });

  return (
    <div
      ref={overlay ? divRef : null}
      className={`tabsubcont ${!backgroundColor ? 'bg-white' : ''} text-black ${className}`}
      style={{ ...style, backgroundColor, touchAction: overlay && swiping ? 'none' : 'unset', overscrollBehavior: 'none' }}
    >
      {children}
    </div>
  );
};

export default Body;
